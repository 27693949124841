<template>
  <v-dialog v-model="mymodal" persistent width="300">
    <v-card>
      <v-card-title class="headline grey lighten-3" primary-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <p class="mt-3">{{ message }}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :loading="btn_load"
          text
          @click="$emit('confirm')"
          >Aceptar</v-btn
        >
        <v-btn color="red" text @click="$emit('close')">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    mymodal: Boolean,
    title: String,
    message: String,
    btn_load: Boolean,
    // likes: Number,
    // commentIds: Array,
    // author: Object,
    // callback: Function,
    // contactsPromise: Promise, // or any other constructor
  },
};
</script>
